<template>
  <div id="BarCom">
    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <div id="shadeCom" v-if="$root.barComShadeShow">
        <div class="app">
          <slot name="shade"></slot>
        </div>
        <div class="on close" @click="$s();$barComShadeToggle()"><i class="far fa-times-circle"></i></div>
      </div>
    </transition>
    <div id="headerCom">
      <div class="app">
        <div class="separator"></div>
        <slot name="header"></slot>
      </div>
      <div class="switch-shade icon pointer" @click="$s();$barComShadeToggle()"><i class="fas fa-ellipsis-v"></i></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  #shadeCom {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2500;
    background-color: $bg-051;
    color:$color-049;
    animation-duration: 0.2s;
    padding:40px 70px 40px 40px;
    overflow-y: auto;

    .close {
      padding: 20px;
      cursor: pointer;
      position: fixed;
      top:0;
      right:10px;
      font-size: 2rem;
    }
    .close:hover {
      color: $color-114;
      transition: all 0.2s;
    }
    a {
      color:$color-049;
    }
    a:hover {
      color: $color-114;
      transition: all 0.2s;
    }
    .app {
      color:$color-049;
      h2 {
        font-size: 1.5rem;
        margin-bottom: 15px;
      }
      .item {
        position: relative;
        cursor:default;
      }
      .item.input {
        margin-bottom: 10px;
      }
      .icon {
        position:relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        font-size: 1rem;
      }
      .icon i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .icon.pointer:hover {
        color: $color-037;
      }
      .text {
        margin-bottom: 3px;
      }
      .input input {
        position: relative;
        width: 100px;
        height: 30px;
        border: none!important;
        font-size: 0.875rem;
        color:$color-001;
        padding: 0 6px;
        background-color: $bg-045;
      }
    }
  }

  #headerCom {
    position: fixed;
    height: $bar-height;
    width: 90%;
    width: calc(100% - 80px);
    overflow: hidden;
    top: 0;
    right: 0;
    color:$color-030;
    z-index:2000;

    .app {
      display:inline-block;
      white-space: nowrap;
      height: 100%;
      width: 100%;
    }
    .app .separator {
      float:left;
      position: relative;
      width: 1px;
      height:50%;
      background-color: $bg-067;
      margin:0 6px;
      top: 50%;
      transform: translateY(-50%);
    }
    .item {
      position: relative;
      height:100%;
      float: left;
      cursor:default;
    }
    .icon {
    width:40px;
    }
    .icon i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .text {
      white-space: nowrap;
      padding: 0 10px;
      line-height: $bar-height;
    }
    .pointer:hover {
      color: $color-037;
    }
    .active {
      color: $bg-111!important;
    }

    .input input {
      position: relative;
      height:80%;
      width: 100px;
      border: none!important;
      font-size: 0.875rem;
      color:$color-001;
      padding: 0 6px;
      background-color: $bg-045;
      top: 50%;
      transform: translateY(-50%);
    }
    .switch-shade {
      position: absolute;
      height: 100%;
      right:0;
      top:0;
      font-size: 1.825rem;
      background-color: $bg-055;
    }
  }

  .mobile {
    #shadeCom {
      padding:30px 20px 20px 20px;
      .close {
        right:0;
      }
      .app {
        .input input {
          width: 120px;
          height: 36px;
          font-size: 1.125rem;
          padding: 0 6px;
        }
        .icon {
        width: 36px;
        height: 36px;
        font-size: 1.125rem;
        }
      }
    }
    #headerCom {
      height: $bar-height-mobile;
      .text {
        line-height: $bar-height-mobile;
      }
    }
  }
</style>