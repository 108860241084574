<template>
  <div>
    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
      <div class="modal-backdrop" v-if="$root.modal==this.id"></div>
    </transition>
    <div class="modal" :class="mode" v-if="$root.modal==this.id">
      <div class="content">
        <slot></slot>
        <div class="close" @click="$s();$modal('hide')"><i class="fas fa-times"></i></div>
        <div class="loading" v-if="$root.loadingModal">
          <i class="spinner fab fa-instalod fa-spin"></i>
          <div class="log"><div v-html="$root.loadingLog"></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    mode: String
  },
}
</script>
<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top:0;
  bottom:0;
  left:0;
  right:0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 4000;
  animation-duration: 0.3s;
}
.modal {
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 4001;

  .content {
    position: relative;
    max-width: 600px;
    margin: 30px auto;
    background-color: $bg-049;
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,0.2);
    background-clip: padding-box;
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);

    .close {
      position: absolute;
      top:5px;
      right:5px;
      padding:10px;
      cursor:pointer;
      font-size: 1.125rem;
      color:$color-020;

      &:hover {
          color:$color-015;
      }
    }
  }
  
  .loading {
    position: absolute;
    height: 100%;
    width: 100%;
    top:0;
    left:0;
    background-color: rgba(255,255,255, 0.8);
    border-radius: 6px;

    .spinner {
      position: absolute;
      font-size: 2.5rem;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      color:$color-020;
    }
    .log {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: 25px;
      width:100%;
      text-align: center;

      div {
        display: inline-block;
        font-size: 1rem;
        line-height: 1.75rem;
        color:$color-010;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 0 10px;
      }
    }
  }
  &.fullscreen {
    .content {
      max-width: 100%;
      min-height: 100%;
      margin: 0;
      border: none;
      border-radius: 0;
    }
  }
}
</style>
<style lang="scss">
.modal {
  .header {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:49px;
    font-size: 1rem;
    padding: 15px 30px 15px 15px;
    border-bottom: 1px solid $bg-037;
  }
  .body {
    padding: calc(15px + 49px) 15px calc(15px + 57px) 15px;

    form {
      .item {
        margin: 0 0 20px 0;
        overflow: hidden;

        .label {
          float: left;
          width: 30%;
          // height: 30px;
          text-align: right;
          padding: 0 20px 0 0;
          line-height: 30px;
          font-weight: 600;
          color:$color-007;
        }
        .field {
          float: left;
          width: 70%;
          line-height: 30px;
          padding-left: 10px;
          background-color: $bg-049;

          input[type="text"], input[type="number"],  textarea {
            width:90%;
            display: block;
            padding: 6px 0;
            font-size: 1rem;
            color:$color-010;
          }
          input[type="text"], input[type="number"] {
            border: none;
            border-bottom: 1px solid $color-030;
            height: 30px;
          }
          .mx-datepicker, input[type="number"] {
            width:150px;
          }
          .mx-datepicker input {
            width:100%;
          }
          textarea {
            height: 90px;
            max-height: 90px;
            max-width: 100%;
            font-size: 0.95rem;
            line-height: 1.25rem;
            border: 1px solid $color-030;
            padding:5px 9px;
          }
          input[type="checkbox"] {
            height: 30px;
            width: 15px;
            cursor: pointer;
          }
          select {
            font-size:0.875rem;
            min-width: 200px;
            border: 1px solid $color-030;
            padding:5px 10px;
            cursor: pointer;
            background-color: $bg-049;
          }

          &.error {
            textarea {
              border: 1px solid $color-625;
            }
            input {
              border-bottom: 1px solid $color-625;
            }
            .errors {
              margin: 5px 13px 0 13px;
              color:$color-631;
              font-size:0.95rem;
            }
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height:57px;
    padding: 10px;
    font-size: 1rem;
    border-top: 1px solid $color-037;
    overflow: hidden;

    button {
      float: right;
      margin: 2px 5px 2px 5px;

      &.left {
        float: left;
      }
    }
  }
}

@media (max-width: 500px) {
  .modal {
    .body {
      form {
        .item {
          .field {
            .mx-datepicker, input[type="number"] {
              width:100%;
            }
            input[type="text"], input[type="number"],  textarea {
              width:100%;
            }
            select {
              width:100%;
            }
          }
        }
      }
    }
  }
}
</style>